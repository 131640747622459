
import {Spacer} from '~/styles';
import {LinksFunction, LoaderArgs, MetaFunction} from '@shopify/remix-oxygen';
import { useLoaderData } from '@remix-run/react';
import { sanityFetch, urlFor } from '~/utils/api/sanity';
import { ISanityData } from '~/utils/types/sanity';
import { MapModulesToUi } from '~/utils/helpers/mapModulesToUi';
import swiperCss from 'swiper/css';
import swiperNavigationCss from 'swiper/css/navigation';
import swiperPaginationCss from 'swiper/css/pagination';
import swiperScrollbarCss from 'swiper/css/scrollbar';
import swiperFadeCss from 'swiper/css/effect-fade'
import swiperCoverflowCss from 'swiper/css/effect-coverflow'

export const meta: MetaFunction<typeof loader> = ({data}) => {
  if (data.sanityData.seo) {
    return {
      title: (data.sanityData.seo && data.sanityData.seo.title) || 'Lepa Vida',
      description: (data.sanityData.seo && data.sanityData.seo.description) || 'Index Page',
      "og:title": (data.sanityData.seo && data.sanityData.seo.title) || 'Lepa Vida',
      "og:description": (data.sanityData.seo && data.sanityData.seo.description) || 'Index Page',
      "og:image": data.sanityData.seo && data.sanityData.seo.image && urlFor(data.sanityData.seo.image.asset._ref) || "",
      "og:type": "index page"
    };
  }
  return {
    
  }
};

export const links: LinksFunction = () => [
  {
    rel: "stylesheet",
    href: swiperCss,
  },
  {
    rel: "stylesheet",
    href: swiperNavigationCss,
  },
  {
    rel: "stylesheet",
    href: swiperPaginationCss,
  },
  {
    rel: "stylesheet",
    href: swiperScrollbarCss,
  },
  {
    rel: "stylesheet",
    href: swiperFadeCss,
  },
  {
    rel: "stylesheet",
    href: swiperCoverflowCss,
  },
];


let productFeaturedColors: string[] = [];

export const loader = async ({context: {storefront}}: LoaderArgs): Promise<{ shopifyData: unknown, sanityData: ISanityData }> => {
  const sanityData: ISanityData = await sanityFetch(`*[_type == "home"][0]{
    title,
    modules,
    slug,
    seo
  }`);
  const productObjs = sanityData.modules && sanityData.modules.find((module) => 
  module._type === 'module.ourWinesProducts' //extract sellected products from the modules
  );
  const productRefs = productObjs ? productObjs.products.map((product: any) => {
    return product.product._ref; //get the sanity product refferences from the array
  }) : [];

  // productFeaturedColors = productObjs ? productObjs.products.map((product: any) => {
  //   return product.featuredColor.hex; //get the featured collors for the products
  // }) : [];

  if (sanityData.modules && sanityData.modules.find(module => module._type === 'module.productsGrid')) {
    let moduleProductRefs: any[] = [];
    moduleProductRefs = sanityData.modules.find(module => module._type === 'module.productsGrid').products.map((product: any) => product.product._ref)
    let queque = moduleProductRefs.map((ref: string) => ` _id == "${ref}" `).join('||')
    await sanityFetch(`*[_type == "product" && ${ queque }]`).then(res => sanityData.productsGridRefData = res);
  }
  if (sanityData.modules && sanityData.modules.find(module => module._type === 'module.productsSlider')) {
    let moduleProductRefs: any[] = [];
    moduleProductRefs = sanityData.modules.find(module => module._type === 'module.productsSlider').products.map((product: any) => product.product._ref)
    let queque = moduleProductRefs.map((ref: string) => ` _id == "${ref}" `).join('||')
    await sanityFetch(`*[_type == "product" && ${ queque }]`).then(res => sanityData.productsSliderRefData = res);
  }

  const query = productRefs.map((ref: string) => ` _id == "${ref}" `).join('||') // generate GROQ query to get all product data
  const sanityProducts: Array<{store: {gid: string}, featuredColor: {hex: string}}> = (productObjs && query) ? await sanityFetch(`*[_type == "product" && ${ query }]{ store { gid }, featuredColor {hex} }`) : null; //get product shopify ids from sanity
  const productIds = sanityProducts && sanityProducts.map((product) => ({store: {gid: product.store.gid}}));
  sanityData['productFeaturedColors'] = sanityProducts && sanityProducts.map((product) => product.featuredColor ? product.featuredColor.hex : '')
  
  const shopifyData = productObjs ? await storefront.query( // get products from shopify
    `#graphql
    query {
        nodes(ids: [    ${productIds.map(({store : {gid}}: {store: {gid: string}}) => {
          return '"' + gid + '",';
        })}]) {
          ...on Product {
            title
            description
            handle
            variants(first: 5) {
              nodes {
                id
                sku
                title
                price {
                  amount
                  currencyCode
                }
              }
            }
            images(first: 5) {
              nodes {
                url
              }
            }
            info: metafield(namespace: "custom", key: "volume_and_alk") {
              value
            }
          }
        }
      
    }
    `,
  ) : {nodes: []};
  return { shopifyData, sanityData };
};

export default function Index() {
  const {shopifyData, sanityData} = useLoaderData<{shopifyData: {nodes: any[]}, sanityData: ISanityData}>();

  return (
    <>
    <Spacer />
    <MapModulesToUi sanityData={sanityData} shopifyData={shopifyData}/>
    </>
  );
}
